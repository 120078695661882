<template>
    <DelayHydration>
        <footer
            id="footer-landmark"
            class="footer layout__footer"
        >
            <div class="footer__container">
                <div
                    v-if="footerData.footerContact"
                    class="footer__contact"
                >
                    <BaseRichText
                        :content="footerData.footerContact"
                    />
                </div>

                <div class="footer__sitemap">
                    <nav
                        class="footer__primary-navigation"
                    >
                        <NuxtLink
                            class="dn-button base-button base-button--link"
                            to="/over-tln"
                        >
                            Over TLN
                        </NuxtLink>
                        <ul v-if="footerData.footerPrimaryNavigation && footerData.footerPrimaryNavigation.length">
                            <li
                                v-for="(link, index) in footerData.footerPrimaryNavigation"
                                :key="`footer-primary-${index}`"
                            >
                                <CraftLink :link="link.linkField">
                                    {{ link.linkField.text }}
                                </CraftLink>
                            </li>
                        </ul>
                    </nav>

                    <nav
                        class="footer__secondary-navigation"
                    >
                        <NuxtLink 
                            class="dn-button base-button base-button--link"
                            to="/kennis"
                        >
                            Kennis
                        </NuxtLink>
                        <ul v-if="footerData.footerSecondaryNavigation && footerData.footerSecondaryNavigation.length">
                            <li
                                v-for="(link, index) in footerData.footerSecondaryNavigation"
                                :key="`footer-secondary-${index}`"
                            >
                                <CraftLink :link="link.linkField">
                                    {{ link.linkField.text }}
                                </CraftLink>
                            </li>
                        </ul>
                    </nav>

                    <nav
                        class="footer__tertiary-navigation"
                    >
                        <ul v-if="footerData.footerTertiaryNavigation && footerData.footerTertiaryNavigation.length">
                            <li
                                v-for="(link, index) in footerData.footerTertiaryNavigation"
                                :key="`footer-tertiary-${index}`"
                            >
                                <CraftLink :link="link.linkField">
                                    {{ link.linkField.text }}
                                </CraftLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div
                v-if="footerData.footerPartners && footerData.footerPartners.length"
                class="footer__logos"
            >
                <div class="footer__logos-container">
                    <CraftLink
                        v-for="(partner, index) in footerData.footerPartners"
                        :key="`footer-logo-${index}`"
                        :link="partner.linkField"
                        class="footer__logo"
                    >
                        <BaseImage
                            :image="partner.logo[0]"
                            :title="partner.logo[0].title"
                            :width="partner.logo[0].width"
                            :height="partner.logo[0].height"
                            sizes="(min-width: 780px) 100px, 75px"
                        />
                    </CraftLink>
                </div>
            </div>

            <div class="footer__meta">
                <div class="footer__meta-container">
                    <div class="footer__meta__navigation">
                        <span>
                            © {{ new Date().getFullYear() }} TLN
                        </span>

                        <CraftLink
                            v-for="(item, index) in footerData.footerMeta"
                            :key="`footer-meta-${index}`"
                            :link="item.linkField"
                        >
                            {{ item.linkField.text }}
                        </CraftLink>
                    </div>

                    <div class="footer__meta__socials">
                        <NuxtLink
                            v-for="(item, index) in socialMedia"
                            :key="`footer-social-${index}`"
                            :to="item.url"
                        >
                            <BaseIcon :icon="item.icon" />
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </footer>
    </DelayHydration>
</template>

<script setup>
import FooterQuery from '~/graphql/queries/global/Footer.graphql';

const socialMedia = [
    {
        icon: 'facebook',
        url: 'https://www.facebook.com/groups/834508940409452'
    },
    {
        icon: 'x',
        url: 'https://twitter.com/TLNnieuws'
    },
    {
        icon: 'linkedin',
        url: 'https://www.linkedin.com/company/transport-en-logistiek-nederland'
    },
    {
        icon: 'youtube',
        url: 'https://www.youtube.com/channel/UCisnoTABu3eQkQ_QWZR-fIg'
    }
];

const { variables } = useCraftGraphql();
// Ignore the URI in this case
delete variables.uri;

const { data } = await useLazyAsyncQuery({
    query: FooterQuery
});

const footerData = computed(() => data?.value?.globalSets[0] ?? []);
</script>

<style lang="less" src="./TheFooter.less" />
